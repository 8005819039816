// 引用两个插件
import html2canvas from 'html2canvas'; // 转为图片
import printJS from 'print-js' // 打印
import disableScroll from 'disable-scroll';

export default {
    name: "WarrantSearch",
    created() {
        // this.$$.navbarAnimation()
        this.$nextTick(function () {

            // 监听屏幕
            this.MonitorScreen()
            // 搜索框显示
            for (let i = 0, max = this.selectData; i < max.length; i++) {
                this.ariseCombobox(i, this.selectData[i].data[0].name)
            }
            // 获得搜索框数据
            this.getSelectorSearchData()

        });
    },
    mounted() {
        this.$nextTick(function () { })
    },
    data() {
        return {
            selectData: [
                {
                    name: this.$t('WSe.AU'),
                    data: [{
                        id: 0,
                        name: this.$t('WSe.AU/CA'),
                        value: 'all',
                        visible: false,
                        show: true
                    }, {
                        id: 1,
                        name: this.$t('WSe.CA'),
                        value: '',
                        visible: false,
                        show: false
                    }, {
                        id: 2,
                        name: this.$t('WSe.AU'),
                        value: 'all',
                        visible: false,
                        show: false
                    }]
                },
                {
                    name: this.$t('WSe.C&P'),
                    data: [{
                        name: `${this.$t('WSe.Ca')} & ${this.$t('WSe.Pu')}`,
                        value: 'all'
                    },
                    {
                        name: this.$t('WSe.Ca'),
                        value: 'call'
                    },
                    {
                        name: this.$t('WSe.Pu'),
                        value: 'put'
                    }
                    ]
                },
                {
                    name: this.$t('WSe.AI'),
                    data: [{
                        name: this.$t('WSe.AI'),
                        value: 'all',
                        visible: false,
                        id: 0
                    }]
                },
                {
                    name: this.$t('int.Se'),
                    data: [{
                        name: this.$t('WSe.As'),
                        value: 'all',
                    },
                    {
                        name: '> 0.8',
                        value: 'mt0.8'
                    },
                    {
                        name: '> 0.9',
                        value: 'mt0.9'
                    },
                    {
                        name: '> 1.0',
                        value: 'mt1.0'
                    }
                    ]
                },
                {
                    name: this.$t('WSe.Amy'),
                    data: null,
                    data: [{
                        name: this.$t('WSe.Amy'),
                        value: 'all'
                    },
                    {
                        name: '> 1 ' + this.$t('WSe.mo'),
                        value: 'mt1'
                    },
                    {
                        name: '> 3 ' + this.$t('WSe.mos'),
                        value: 'mt3'
                    },
                    {
                        name: '> 6 ' + this.$t('WSe.mos'),
                        value: 'mt6'
                    },
                    {
                        name: '> 9 ' + this.$t('WSe.mos'),
                        value: 'mt9'
                    }
                    ]
                },
                {
                    name: this.$t('WSe.Td') + ' (' + this.$t('WSe.da') + ')',
                    data: [{
                        name: this.$t('WSe.Td') + ' (' + this.$t('WSe.da') + ')',
                        value: 'all',
                    },
                    {
                        name: '> 1 ' + this.$t('WSe.day'),
                        value: 'mt1'
                    },
                    {
                        name: '> 3 ' + this.$t('WSe.da'),
                        value: 'mt3'
                    },
                    {
                        name: '> 5 ' + this.$t('WSe.da'),
                        value: 'mt5'
                    },
                    {
                        name: '> 7 ' + this.$t('WSe.da'),
                        value: 'mt7'
                    }
                    ]
                },
                {
                    name: this.$t('WSe.Aeg') + ' (x)',
                    data: [{
                        name: this.$t('WSe.Aeg') + ' (x)',
                        value: 'all'
                    },
                    {
                        name: '> 5x',
                        value: 'mt5'
                    },
                    {
                        name: '> 10x',
                        value: 'mt10'
                    },
                    {
                        name: '> 15x',
                        value: 'mt15'
                    },
                    {
                        name: '> 20x',
                        value: 'mt20'
                    }
                    ]
                },
                {
                    name: this.$t('WSe.Am'),
                    data: [{
                        name: this.$t('WSe.Am'),
                        value: 'all'
                    },
                    {
                        name: this.$t('WSe.Atm'),
                        value: 'atm'
                    },
                    {
                        name: this.$t('WSe.Itm'),
                        value: 'itm'
                    },
                    {
                        name: this.$t('WSe.Ootm'),
                        value: 'otm'
                    }
                    ]
                },
                {
                    name: this.$t("CU.SU"),
                    data: [{}]
                }
            ],
            // 列表头部数据
            tableHeadData: [{
                namef: "Warrant Name",
                name: this.$t('WSe.WN'),
                sort: 'wcode',
                visible: true
            },
            {

                namef: "Indicators",
                name: this.$t('WSe.In'),
                sort: '',
                visible: true
            },
            {
                namef: "Issuer",
                name: this.$t('WSe.WI'),
                sort: 'isr',
                visible: true
            },
            {
                namef: "Type",
                name: this.$t('WSe.ToW'),
                sort: 'type',
                visible: true
            },
            {
                namef: "Days to Last Trading Date",
                name: this.$t('WSe.DtLTD'),
                sort: 'LSTTRDDATE',
                visible: true
            },
            {
                namef: "Last Trading Date",
                name: this.$t('WSe.ED'),
                sort: 'mdate',
                visible: false
            },
            {
                namef: "Exercise Price",
                name: `${this.$t('WSe.EP')}`,
                sort: 'ep',
                visible: false
            },
            {
                namef: "Exercise Ratio",
                name: `${this.$t('WSe.ER')} (DW:1UL)`,
                sort: 'dwps',
                visible: false
            },
            {
                namef: `Traded Volume ('000)`,
                name: `${this.$t('WSe.TV')} ('000)`,
                sort: 'tv',
                visible: false
            },
            //  pat2
            {
                namef: "Bid Price",
                name: this.$t('hp.BP'),
                sort: 'bp',
                visible: true
            },
            {
                namef: "Bid Change (%)",
                name: this.$t('WSe.BC') + ' (%)',
                sort: 'pc',
                visible: false
            },
            {
                namef: "Bid Volume ('000)",
                name: this.$t('WSe.BV') + " ('000)",
                sort: 'bv',
                visible: false
            },
            {
                namef: "Offer Price",
                name: this.$t('WSe.OP'),
                sort: 'ap',
                visible: false
            },
            // {
            //     namef: "Offer Change ('%)",
            //     name: this.$t('WSe.OC') + " ('%)",
            //     sort: 'av',
            //     visible: false
            // },
            {
                namef: "Offer Volume ('000)",
                name: this.$t('WSe.OV') + " ('000)",
                sort: 'av',
                visible: false
            },
            // pat3
            {
                namef: "Effective Gearing",
                name: this.$t('int.EG'),
                sort: 'eg',
                visible: true,
                top: 66,
                left: 250,
            },
            {
                namef: "Sensitivity",
                name: this.$t('int.Se'),
                sort: 'sen',
                visible: true,
                top: 66,
                left: 250,
            },
            {
                namef: "Historical Volatility",
                name: this.$t('WSe.HV'),
                sort: 'av',
                visible: false
            },
            {
                namef: "Implied Volatility",
                name: this.$t('int.IV'),
                sort: 'iv',
                visible: true,
                top: 84,
                left: 250,
            },
            {
                namef: "Moneyness",
                name: this.$t('WSe.Mo'),
                sort: 'mn',
                visible: true
            },
            {
                namef: "Premium (%)",
                name: this.$t('int.Pr') + ' (%)',
                sort: 'pre',
                visible: false,
                top: 84,
                left: 250,
            },
            {
                namef: "Delta",
                name: this.$t('int.De'),
                sort: 'dlt',
                visible: false,
            },
            {
                namef: "Theta (THB/days)",
                name: this.$t('int.Th') + ' (THB/'+this.$t("WSe.day")+')',
                sort: 'tt',
                visible: true,
                top: 48,
                left: 250,
            },
            {
                namef: "Theta (%/days)",
                name: this.$t('int.Th') + ' (%/'+this.$t("WSe.day")+')',
                sort: 'tt',
                visible: true,
                top: 48,
                left: 250,
            },
            {
                namef: "Theta (days)",
                name: this.$t('int.Th') + ' ('+this.$t("WSe.day")+')',
                sort: 'tt',
                visible: true,
                top: 48,
                left: 250,
            },
            ],
            conditionData: [{
                icon: require('../../../../../public/image/Daily_Highlight.png'),
                name: this.$t('int.DH'),
                hint: '<strong>' + this.$t('int.DH') + ':</strong> ' + this.$t('int.Stcitm')
            },
            {
                icon: require('../../../../../public/image/More_Responsive.png'),
                name: this.$t('int.MR'),
                hint: '<strong>' + this.$t('int.MR') + ': </strong> ' + this.$t('int.MSsmou')
            },
            {
                icon: require('../../../../../public/image/Low_Time_Decay.png'),
                name: this.$t('int.LTD'),
                hint: '<strong>' + this.$t('int.LTD') + ':</strong> ' + this.$t('int.MSmtd')
            },
            {
                icon: require('../../../../../public/image/Low_Inventory.png'),
                name: this.$t('int.LI'),
                hint: '<strong>' + this.$t('int.LI') + ':</strong> ' + this.$t('int.MScMa')
            },
            {
                icon: require('../../../../../public/image/Near_Expiry.png'),
                name: this.$t('int.NE'),
                hint: '<strong>' + this.$t('int.NE') + ':</strong> ' + this.$t('int.Ddtdb')
            },
            {
                icon: require('../../../../../public/image/Low_DW_Price.png'),
                name: this.$t('int.LDP'),
                hint: '<strong>' + this.$t('int.LDP') + ':</strong> ' + this.$t('int.DbbT')
            }
            ],
            // 列表内容数据
            tableContentData: [],
            publishTime: '',
            underlyingData: ['all'],
            underlyingDackupsData: [],
            issuerData: ['MST'],
            issuerDataText: [],
            sortBy: 'wcode',
            sortOrder: 'asc',
            typeD: 'all',
            maturityD: 'all',
            timedecaryD: 'all',
            moneynessD: 'all',
            effectiveGearingD: 'all',
            expiryD: 'all',
            sensitivityD: 'all',
            indicatorD: 'all',
            // underlying的input text
            underlyingInputText: '',
            logUnderlyingInputText: '',
            // underlying搜索用的数据
            underlyingSeekData: [],
            // issuer的input text
            ssuerInputText: '',
            logSsuerInputText: '',
            // WARRANT的长度
            WARRANTLength: 5,
            MARKETLength: 1,
            INDICATORSLength: 5,
            iosWARRANT: true,
            iosMARKET: true,
            iosINDICATORSF: true,
            // 点击显示数据的数目
            dataLiNum: 13,
            // 全选打勾
            allData: false,
            // 禁止点击
            forbid: false,
            // 下拉菜单的显示
            ifUnderlying: false,
            ifMaybank: false,

            iftableHeadShow: false,
            // 打印去掉箭头
            printif: true,
            scrollBarScrollLeft: 0,
            Results: 0,
            nameFixationTableBoxT: false,
            WarrantComparisonRICArr: [],
            table2Col: 'col'
        }
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 获得搜索框数据
        getSelectorSearchData: function () {
            let _this = this
            $.ajax({
                type: "POST",
                dataType: "json",
                url: this.$$.mibb + "GetScreenerData",
                data: { token: "webkey", init: "1" },
                success: res => {
                    if (res.status == "SUCCESS") {
                        // underlying下拉菜单的数据
                        $.each(res.underlyings, function (iu, cu) {
                            _this.selectData[0].data.push({
                                name: `${cu.underlying_code} (${cu.underlying_name}) `,
                                value: cu.underlying_ticker,
                                visible: false,
                                id: iu + 3,
                                show: true
                            })
                        })
                        this.underlyingSeekData = this.selectData[0].data
                        // issuers下拉菜单的数据
                        $.each(res.issuers, function (is, cs) {
                            _this.selectData[2].data.push({
                                name: cs.toUpperCase(),
                                value: cs.split(' ')[0],
                                visible: false,
                                id: is + 1
                            })
                        })
                        // 获取表格数据
                        if (this.$route.params.ric !== undefined) {
                            this.underlyingData = []
                            $.each(this.selectData[0].data, (i, v) => {
                                if (this.$route.params.ric == v.value) {
                                    this.underlyingData[i] = this.$route.params.ric
                                    v.visible = true
                                }
                            })
                            this.underlyingInputText = this.$route.params.ric
                            this.underlyingSeekData = this.selectData[0].data
                            this.GetScreenerData()

                        } else {
                            this.GetScreenerData()
                        }
                        this.$$.tableWidth($('#WarrantSearch').find('.scrollBar').find('div'), $('#WarrantSearch').find('.tableBox').find('.table'), true)
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        // 获取表格数据
        GetScreenerData: function () {
            this.tableContentData = []
            let _this = this
            $.ajax({
                type: "POST",
                dataType: "json",
                url: this.$$.mibb + "GetScreenerData",
                data: {
                    token: "webkey",
                    underlying: this.underlyingData.toString(),
                    type: this.typeD,
                    issuer: this.issuerData.toString().replace(/,/g, ";"),
                    maturity: this.maturityD,
                    moneyness: this.moneynessD,
                    effectiveGearing: this.effectiveGearingD,
                    expiry: this.timedecaryD,
                    sensitivity: this.sensitivityD,
                    indicator: this.indicatorD,
                    sortBy: this.sortBy,
                    sortOrder: this.sortOrder
                },
                success: res => {
                    if (res.status == "SUCCESS") {
                        if (res.data) {
                            // 菜单input显示text
                            if (this.underlyingData[0] == 'all') {
                                this.underlyingInputText = this.$t('WSe.AU')
                                this.underlyingData = []
                            }
                            if (this.issuerData[0] === 'MST' && this.issuerData.length === 1) {
                                $.each(this.selectData[2].data, function (i, c) {
                                    if (c.value == 'MST') {
                                        c.visible = true
                                        _this.issuerDataText[0] = c.name
                                    }
                                })
                            }
                            this.issuerDataText = this.issuerDataText.filter(tiem => tiem !== '')
                            this.ssuerInputText = this.issuerDataText.toString().toUpperCase()
                            this.tableContentData = res.data
                            $.each(this.tableContentData, (i, c) => {
                                let conditionIcon = [{
                                    icon: require('../../../../../public/image/Daily_Highlight.png'),
                                    visible: 0,
                                    name: '<strong>' + this.$t('int.DH') + ':</strong> ' + this.$t('int.Stcitm')
                                },
                                {
                                    icon: require('../../../../../public/image/More_Responsive.png'),
                                    visible: 0,
                                    name: '<strong>' + this.$t('int.MR') + ': </strong> ' + this.$t('int.MSsmou')
                                },
                                {
                                    icon: require('../../../../../public/image/Low_Time_Decay.png'),
                                    visible: 0,
                                    name: '<strong>' + this.$t('int.LTD') + ':</strong> ' + this.$t('int.MSmtd')
                                },
                                {
                                    icon: require('../../../../../public/image/Low_Inventory.png'),
                                    visible: 0,
                                    name: '<strong>' + this.$t('int.LI') + ':</strong> ' + this.$t('int.MScMa')
                                },
                                {
                                    icon: require('../../../../../public/image/Near_Expiry.png'),
                                    visible: 0,
                                    name: '<strong>' + this.$t('int.NE') + ':</strong> ' + this.$t('int.Ddtdb')
                                },
                                {
                                    icon: require('../../../../../public/image/Low_DW_Price.png'),
                                    visible: 0,
                                    name: '<strong>' + this.$t('int.LDP') + ':</strong> ' + this.$t('int.DbbT')
                                }
                                ]
                                conditionIcon[0].visible = c.daily_highlights
                                conditionIcon[1].visible = c.more_responsive
                                conditionIcon[2].visible = c.low_time_decay
                                conditionIcon[3].visible = c.soldout
                                conditionIcon[4].visible = c.near_expiry
                                conditionIcon[5].visible = c.low_dw_price
                                c.conditionIcon = conditionIcon
                                c.visible = false
                                c.idno = i
                            })
                            this.$forceUpdate()
                            this.publishTime = this.$$.changeToLocalTime1(res.last_update)
                            // this.cloneHead()
                            $('.headTableBox').width($('.tableBox').width())
                            this.tableHeadLocation()
                            this.WarrantNameFixation()
                            this.monitorShift()
                        }

                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        dataOne: function (data, index, e) {
            let arr = [], dindex
            if (e.target.checked) {
                // data.visible = true
                // this.tableContentData.splice(index, 1)
                // this.tableContentData.unshift(data)
                // e.target.checked = false
                this.WarrantComparisonRICArr.push(data.ric)
            } else {
                // data.visible = false
                // $.each(this.tableContentData, (i, c) => {
                //     if (c.idno !== data.idno) {
                //         arr.push(c)
                //     } else {
                //         dindex = i
                //     }
                // })
                // this.tableContentData = arr
                // this.tableContentData.splice(data.idno + dindex, 0, data)
                // e.target.checked = true
                let index = this.WarrantComparisonRICArr.indexOf(data.ric)
                this.WarrantComparisonRICArr.splice(index, 1)
            }
        },
        // 搜索数据
        Submit: function () {
            if (this.underlyingData.length == 0) {
                this.underlyingData = ['all']
            }
            this.GetScreenerData()
        },
        // 重置
        info: function () {
            let timer1
            if (this.underlyingData.length == 0) {
                this.underlyingData = ['all']
            }
            this.GetScreenerData()
            $('#info').attr('disabled', true)
            clearTimeout(timer1);
            //设置一次性定时器
            timer1 = setTimeout(function () {
                $('#info').attr('disabled', false)
            }, 5000);
            this.handleMouse(false)
        },
        // 重置提示
        handleMouse: function (ifov) {
            this.mouse = ifov
        },
        // 下拉菜单显示
        ComboBoxShow: function (ifv) {
            switch (ifv) {
                case 1:
                    let Tarr = [],
                        Farr = []
                    this.ifUnderlying = !this.ifUnderlying
                    this.ifMaybank = false
                    this.iftableHeadShow = false
                    Tarr = this.underlyingSeekData.filter(tiem => {
                        return tiem.visible == true
                    })
                    Farr = this.underlyingSeekData.filter(tiem => {
                        return tiem.visible == false
                    })
                    if (this.underlyingData[0] == 'all') {
                        this.selectData[0].data = [...Farr, ...Tarr]
                    } else {
                        this.selectData[0].data = [...Tarr, ...Farr]
                    }
                    break;
                case 2:
                    this.ifMaybank = !this.ifMaybank
                    this.ifUnderlying = false
                    this.iftableHeadShow = false
                    break;
                default:
                    this.iftableHeadShow = !this.iftableHeadShow
                    this.ifMaybank = false
                    this.ifUnderlying = false
                    break;
            }
        },
        // 下拉菜单消失
        ComboBoxHide: function (e) {
            this.ifUnderlying = false
            this.ifMaybank = false
            this.iftableHeadShow = false
        },
        // underlying下拉菜单的方法
        underlyingFun: function (data, e) {
            let len
            if (data.id == 0) {
                this.selectData[0].data[0].show = false
                this.selectData[0].data[0].visible = false
                this.selectData[0].data[1].show = true
                this.selectData[0].data[2].show = true
                this.selectData[0].data[2].visible = true
                this.underlyingData = []
                this.underlyingInputText = this.$t('WSe.AU')
                this.logUnderlyingInputText = this.$t('WSe.AU')
                setTimeout(function () {
                    $('.WarrantSearchHunt0').find('li').find('.input2').attr("disabled", "disabled")
                }, 10);
                $.each(this.selectData[0].data, (i, c) => {
                    c.visible = true
                    if (c.id == 0 || c.id == 1) {
                        this.underlyingSeekData[i].visible = false
                    } else {
                        this.underlyingSeekData[i].visible = true
                    }
                    if (i >= 3) {
                        this.underlyingData[i] = c.value
                    }
                })
            }
            if (data.id == 1) {
                this.selectData[0].data[0].show = true
                this.selectData[0].data[1].show = false
                this.selectData[0].data[1].visible = false
                this.selectData[0].data[2].show = false
                this.selectData[0].data[2].visible = false
                this.underlyingData = []
                this.underlyingInputText = ''
                this.logUnderlyingInputText = ''
                $.each(this.selectData[0].data, (i, c) => {
                    c.visible = false
                    this.underlyingSeekData[i].visible = false
                })
            }
            if (data.id == 2) {
                this.selectData[0].data[1].visible = false
                this.selectData[0].data[2].visible = true
                this.underlyingData = ['all']
                this.underlyingInputText = this.$t('WSe.AU')
                this.logUnderlyingInputText = this.$t('WSe.AU')
                $.each(this.selectData[0].data, (i, c) => {
                    c.visible = true
                    if (c.id == 0 || c.id == 1) {
                        this.underlyingSeekData[i].visible = false
                    } else {
                        this.underlyingSeekData[i].visible = true
                    }
                })
            }
            if (data.id >= 3) {

                if (this.underlyingData.length == 1) {

                    this.underlyingData = []
                }
                this.underlyingSeekData[1].visible = false
                data.visible = e.target.checked
                this.underlyingSeekData[this.underlyingSeekData.indexOf(data)].visible = e.target.checked
                if (e.target.checked) {
                    this.underlyingData[data.id] = data.value
                } else {
                    this.underlyingData[data.id] = ''
                }
                len = this.underlyingData.filter(tiem => tiem !== '')
                if (!len.length) {
                    this.underlyingSeekData[0].show = true
                    this.underlyingSeekData[0].visible = false
                    this.underlyingSeekData[1].show = false
                    this.underlyingSeekData[2].show = false
                } else {
                    this.underlyingSeekData[0].show = false
                    this.underlyingSeekData[0].visible = false
                    this.underlyingSeekData[1].show = true
                    this.underlyingSeekData[2].show = true
                    if (len.length !== this.underlyingSeekData.length - 3) {
                        if (this.selectData[0].data[2].name == this.$t('WSe.AU')) {
                            this.selectData[0].data[2].visible = false
                        }
                        this.underlyingSeekData[2].visible = false
                        setTimeout(function () {
                            $('.WarrantSearchHunt0').find('li').find('.input2').removeAttr("disabled")
                        }, 10);
                    } else {
                        if (this.selectData[0].data[2].name == this.$t('WSe.AU')) {
                            this.selectData[0].data[2].visible = true
                        }
                        this.underlyingSeekData[2].visible = true
                        setTimeout(function () {
                            $('.WarrantSearchHunt0').find('li').find('.input2').attr("disabled", "disabled")
                        }, 10);
                    }
                }
                this.underlyingInputText = len.toString()
                this.logUnderlyingInputText = len.toString()
            }
            this.GetScreenerData()
        },
        // underlying下拉菜单搜索
        underlyingSeek: function (e) {
            let Tarr = [],
                Farr = []
            this.underlyingInputText = e.target.value
            if (e.target.value.length == 0) {
                this.selectData[0].data = this.underlyingSeekData
            } else {
                this.selectData[0].data = this.$$.filtration(this.underlyingSeekData, e.target.value)
            }
            Tarr = this.selectData[0].data.filter(tiem => {
                return tiem.visible == true
            })
            Farr = this.selectData[0].data.filter(tiem => {
                return tiem.visible == false
            })
            this.selectData[0].data = [...Tarr, ...Farr]
            this.ifUnderlying = true

        },
        // issuer下拉菜单的方法
        issuerFun: function (data, e) {
            let _this = this
            let islen
            if (data.id == 0) {
                this.issuerData = []
                this.issuerDataText = []
                if (e.target.checked) {
                    this.ssuerInputText = this.$t('WSe.AI')
                } else {
                    this.ssuerInputText = ''
                }
                $.each(this.selectData[2].data, function (i, c) {
                    if (i >= 1) {
                        if (e.target.checked) {
                            _this.issuerData.push(c.value)
                            _this.issuerDataText.push(c.name)
                        } else {
                            _this.issuerData = []
                            _this.issuerDataText = []
                        }
                    }
                    c.visible = e.target.checked
                })
            }

            if (data.id > 0) {
                data.visible = e.target.checked
                if (e.target.checked) {
                    this.issuerData[data.id - 1] = data.value
                    this.issuerDataText[data.id - 1] = data.name
                } else {
                    this.issuerData[data.id - 1] = ''
                    this.issuerDataText[data.id - 1] = ''
                }
            }
            islen = this.issuerDataText.filter(tiem => tiem !== '')
            console.log(islen);
            if (islen.length == this.selectData[2].data.length - 1) {
                this.selectData[2].data[0].visible = true
                this.ssuerInputText = this.$t('WSe.AI')
                this.logSsuerInputText = this.$t('WSe.AI')
            } else {
                this.selectData[2].data[0].visible = false
                this.ssuerInputText = islen.toString().toUpperCase()
                this.logSsuerInputText = islen.toString().toUpperCase()
            }
            // this.GetScreenerData()

        },
        // 点击选框来显示数据
        doneTask: function (index, e) {
            this.tableHeadData[index].visible = e.target.checked
            if (e.target.checked) {
                if (index <= 8) {
                    this.WARRANTLength++
                    this.iosWARRANT = true
                    if (index == 0 && this.scrollBarScrollLeft > 0) {
                        $('.nameFixationTableBox').css({
                            display: 'flex'
                        })
                        this.WarrantNameFixation()
                    }
                } else if (index > 8 && index <= 13) {


                    this.MARKETLength++
                    if (this.MARKETLength <= 3) {
                        if (this.MARKETLength == 1) {
                            this.table2Col = 'col-2'
                        } else {
                            this.table2Col = 'col-' + this.MARKETLength
                        }
                    } else {
                        this.table2Col = 'col'
                    }
                    this.iosMARKET = true
                } else if (index > 13) {
                    this.INDICATORSLength++
                    this.iosINDICATORSF = true
                }
                this.dataLiNum++
            } else {
                if (index <= 8) {
                    this.WARRANTLength--
                    if (this.WARRANTLength == 0) {
                        this.iosWARRANT = false
                    }
                    if (index == 0 && this.scrollBarScrollLeft > 0) {
                        $('.nameFixationTableBox').hide()
                    }
                } else if (index > 8 && index <= 13) {
                    this.MARKETLength--
                    if (this.MARKETLength <= 3) {
                        if (this.MARKETLength == 1) {
                            this.table2Col = 'col-2'
                        } else {
                            this.table2Col = 'col-' + this.MARKETLength
                        }
                    } else {
                        this.table2Col = 'col'
                    }
                    if (this.MARKETLength == 0) {
                        this.iosMARKET = false
                    }
                } else if (index > 13) {
                    this.INDICATORSLength--
                    if (this.INDICATORSLength == 0) {
                        this.iosINDICATORSF = false
                    }
                }
                this.dataLiNum--
            }
            if (this.dataLiNum === this.tableHeadData.length) {
                this.allData = true
            } else {
                this.allData = false
            }
            this.$$.tableWidth($('#WarrantSearch').find('.scrollBar').find('div'), $('#WarrantSearch').find('.tableBox').find('.table'), true)
            this.WarrantNameFixation()
        },
        // 是否全选显示数据
        allIfData: function (e) {
            if (e.target.checked) {
                this.allData = e.target.checked
                this.dataLiNum = this.tableHeadData.length
                this.WARRANTLength = 9
                this.MARKETLength = 5
                this.INDICATORSLength = 10
                this.iosWARRANT = true
                this.iosMARKET = true
                this.iosINDICATORSF = true
            } else {
                this.allData = e.target.checked
                this.dataLiNum = 0
                this.WARRANTLength = 0
                this.MARKETLength = 0
                this.INDICATORSLength = 0
                this.iosWARRANT = false
                this.iosMARKET = false
                this.iosINDICATORSF = false
            }
            if (this.MARKETLength <= 3) {
                if (this.MARKETLength == 1) {
                    this.table2Col = 'col-2'
                } else {
                    this.table2Col = 'col-' + this.MARKETLength
                }
            } else {
                this.table2Col = 'col'
            }
            this.monitorShift()
            this.$$.tableWidth($('#WarrantSearch').find('.scrollBar').find('div'), $('#WarrantSearch').find('.tableBox').find('.table'), true)
            this.WarrantNameFixation()
            $.each(this.tableHeadData, function (i, c) {
                c.visible = e.target.checked
            })
        },
        // 是否恢复原样
        ifStood: function (e) {
            if ($(window).width() <= 992) {
                if (e.target.checked) {
                    this.WARRANTLength = 3
                    this.MARKETLength = 1
                    this.INDICATORSLength = 0
                    this.iosINDICATORSF = false
                    $.each(this.tableHeadData, (i, c) => {
                        if (i !== 0 && i !== 4 && i !== 6 && i !== 9) {
                            c.visible = false
                        }
                    })
                    this.dataLiNum = 13
                    this.allData = false
                    this.iosWARRANT = true
                    this.iosMARKET = true
                    this.forbid = e.target.checked
                } else {
                    this.forbid = e.target.checked
                }
            } else {
                if (e.target.checked) {
                    $.each(this.tableHeadData, function (i, c) {
                        if (i !== 5 && i !== 6 && i !== 7 && i !== 8 && i !== 10 && i !== 11 && i !== 12 && i !== 13 && i !== 16 && i !== 19 && i !== 20 && i !== 22) {
                            c.visible = true
                        } else {
                            c.visible = false
                        }
                    })
                    this.WARRANTLength = 5
                    this.MARKETLength = 1
                    this.INDICATORSLength = 6
                    this.dataLiNum = 13
                    this.allData = false
                    this.iosWARRANT = true
                    this.iosMARKET = true
                    this.iosINDICATORSF = true
                    this.forbid = e.target.checked
                } else {
                    this.forbid = e.target.checked
                }
            }
            if (this.MARKETLength <= 4) {
                if (this.MARKETLength == 1) {
                    this.table2Col = 'col-2'
                } else {
                    this.table2Col = 'col-' + this.MARKETLength
                }
            } else {
                this.table2Col = 'col'
            }
            this.monitorShift()
            this.$$.tableWidth($('#WarrantSearch').find('.scrollBar').find('div'), $('#WarrantSearch').find('.tableBox').find('.table'), true)
            this.WarrantNameFixation()
        },
        // 排序
        ordering: function (sortBy, sortOrder) {
            this.sortBy = sortBy
            this.sortOrder = sortOrder
            if (this.underlyingData.length == 0) {
                this.underlyingData = ['all']
            }
            this.GetScreenerData()
        },
        // 清空输入框
        emptyInput: function (val, logVal, v) {
            this[val] = ''
        },
        // 回复输入框
        recoverInput: function (val, logVal) {
            this[val] = logVal
        },
        // table 头部定位
        tableHeadLocation: function () {
            let _this = this,
                width2
            $('.nameFixationTableBox').find('.table1').find('.table').width($('#WarrantSearch').find('.tableBox').find('.table1').outerWidth(true))
            $('.NameTableBox').find('.table1').find('.table').width($('#WarrantSearch').find('.tableBox').find('.table1').outerWidth(true))
            $('.nameFixationTableBox').width($('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(0).outerWidth(true) + $('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(1).outerWidth(true))
            $('.NameTableBox').width($('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(0).outerWidth(true) + $('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(1).outerWidth(true))
            $(window).scroll(function () {
                if ($(this).scrollTop() > ($('#WarrantSearch').find('.tableBox').offset().top + 119)) {
                    if ($(this).scrollTop() < $('#WarrantSearch').find('.bottom-disclaimer').offset().top - 200) {
                        $('.headTableBox').css({
                            display: 'flex'
                        })
                        $('.NameTableBox').css({
                            display: 'flex'
                        })



                    } else {
                        $('.headTableBox').hide()
                        $('.NameTableBox').hide()
                    }

                } else {

                    $('.headTableBox').hide()
                    $('.NameTableBox').hide()
                }
            })
        },
        // 监听屏幕
        MonitorScreen: function () {
            if ($(window).width() <= 992) {
                this.WARRANTLength = 3
                this.MARKETLength = 1
                this.INDICATORSLength = 0
                this.iosINDICATORSF = false
                this.allData = false
                $.each(this.tableHeadData, (i, c) => {
                    if (i !== 0 && i !== 4 && i !== 6 && i !== 9) {
                        c.visible = false
                    }
                })
                this.nameFixationTableBoxT = false

            } else {
                this.WARRANTLength = 5
                this.MARKETLength = 1
                this.INDICATORSLength = 6
                this.iosINDICATORSF = true
                this.dataLiNum = 14
                this.allData = false
                $.each(this.tableHeadData, (i, c) => {
                    if (i !== 5 && i !== 6 && i !== 7 && i !== 8 && i !== 10 && i !== 11 && i !== 12 && i !== 13 && i !== 16 && i !== 19 && i !== 20 && i !== 22) {
                        c.visible = true
                    } else {
                        c.visible = false
                    }
                })
                this.nameFixationTableBoxT = true
            }
            if (this.MARKETLength <= 3) {
                if (this.MARKETLength == 1) {
                    this.table2Col = 'col-2'
                } else {
                    this.table2Col = 'col-' + this.MARKETLength
                }
             
            } else {
                this.table2Col = 'col'
            }
            this.$$.tableWidth($('#WarrantSearch').find('.scrollBar').find('div'), $('#WarrantSearch').find('.tableBox').find('.table'), true)
            let _this = this
            if (!this.$$._isMobile()) {
                $(window).resize(() => {
                    console.log(1);
                    $('.nameFixationTableBox').find('.table1').find('.table').width($('#WarrantSearch').find('.tableBox').find('.table1').outerWidth(true))
                    $('.NameTableBox').find('.table1').find('.table').width($('#WarrantSearch').find('.tableBox').find('.table1').outerWidth(true))
                    $('.nameFixationTableBox').width($('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(0).outerWidth(true) + $('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(1).outerWidth(true))
                    $('.NameTableBox').width($('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(0).outerWidth(true) + $('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(1).outerWidth(true))
                    $('.headTableBox').width($('.tableBox').width())
                    if ($(window).width() <= 992) {
                        this.WARRANTLength = 3
                        this.MARKETLength = 1
                        this.INDICATORSLength = 0
                        this.iosINDICATORSF = false
                        this.allData = false
                        $.each(this.tableHeadData, (i, c) => {
                            if (i !== 0 && i !== 4 && i !== 6 && i !== 9) {
                                c.visible = false
                            }
                        })
                        _this.nameFixationTableBoxT = false

                    } else {
                        this.WARRANTLength = 5
                        this.MARKETLength = 1
                        this.INDICATORSLength = 6
                        this.iosINDICATORSF = true
                        this.dataLiNum = 14
                        this.allData = false
                        $.each(this.tableHeadData, (i, c) => {
                            if (i !== 5 && i !== 6 && i !== 7 && i !== 8 && i !== 10 && i !== 11 && i !== 12 && i !== 13 && i !== 16 && i !== 19 && i !== 20 && i !== 22) {
                                c.visible = true
                            } else {
                                c.visible = false
                            }
                        })
                        _this.nameFixationTableBoxT = true
                    }
                    if (this.MARKETLength <= 3) {
                        if (this.MARKETLength == 1) {
                            this.table2Col = 'col-2'
                        } else {
                            this.table2Col = 'col-' + this.MARKETLength
                        }
                    } else {
                        this.table2Col = 'col'
                    }
                    this.$$.tableWidth($('#WarrantSearch').find('.scrollBar').find('div'), $('#WarrantSearch').find('.tableBox').find('.table'), true)
                })
            }

        },
        //  Warrant Name 固定
        WarrantNameFixation: function () {
            let _this = this
            setTimeout(function () {
                $('.nameFixationTableBox').find('.table1').find('.table').width($('#WarrantSearch').find('.tableBox').find('.table1').outerWidth(true))
                $('.NameTableBox').find('.table1').find('.table').width($('#WarrantSearch').find('.tableBox').find('.table1').outerWidth(true))
                $('.nameFixationTableBox').width($('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(0).outerWidth(true) + $('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(1).outerWidth(true))
                $('.NameTableBox').width($('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(0).outerWidth(true) + $('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(1).outerWidth(true))
            }, 10);

            $('#WarrantSearch').find('.tableBox').scroll(function () {
                _this.scrollBarScrollLeft = $(this).scrollLeft()

                $('.headTableBox').scrollLeft($(this).scrollLeft())
                let topNum = 25
                // if (_this.nameFixationTableBoxT) {
                //     topNum = 25
                // } else {
                //     topNum = 25
                // }
                $('.nameFixationTableBox').css({
                    'top': $('#WarrantSearch').find('.mainContent').find('.scope').find('.first').outerHeight(true) + $('#WarrantSearch').find('.mainContent').find('.scope').find('.condition').outerHeight(true) + topNum
                })
                if ($(this).scrollLeft() > 0 && _this.tableHeadData[0].visible) {
                    $('.nameFixationTableBox').css({
                        display: 'flex'
                    })
                } else {
                    $('.nameFixationTableBox').hide()
                }
                // $('.nameFixationTableBox').find('.table1').find('.table').width($('#WarrantSearch').find('.tableBox').find('.table1').outerWidth(true))
                $('.NameTableBox').find('.table1').find('.table').width($('#WarrantSearch').find('.tableBox').find('.table1').outerWidth(true))
                // $('.nameFixationTableBox').width($('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(0).outerWidth(true) + $('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(1).outerWidth(true))
                $('.NameTableBox').width($('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(0).outerWidth(true) + $('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(1).outerWidth(true))
            })
        },
        monitorShift: function () {
            let scopeH = 0,
                windowT = $(window).scrollTop(),
                windowW = $(window).width(),
                scopeW = $('#WarrantSearch').find('.mainContent').find('.scope').width(),
                table1 = 0,
                table2 = 0,
                table3 = 0,
                H = 0
            setTimeout(function () {
                if (windowW > 768) {
                    scopeH = $('#WarrantSearch').find('.mainContent').find('.scope').height() - 300
                    H = 300
                } else {
                    scopeH = $('#WarrantSearch').find('.mainContent').find('.scope').height() + 100
                    H = 600
                }
                if ($('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.table1').length) {
                    table1 = $('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.table1').width()
                }
                if ($('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.table2').length) {
                    table2 = $('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.table2').width()
                }
                if ($('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.table3').length) {
                    table3 = $('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.table3').width()
                }
                if (windowT <= H || windowT > scopeH) {
                    $('#WarrantSearch').find('.mainContent').find('.shift').hide()
                }
                if (windowT > H) {
                    if (scopeW > (table1 + table2 + table3)) {
                        $('#WarrantSearch').find('.mainContent').find('.shift').hide()
                    } else {

                        $('#WarrantSearch').find('.mainContent').find('.shift').show()
                    }
                }
            }, 10)

            $(window).resize(() => {

                windowW = $(window).width()
                if (windowW > 768) {
                    scopeH = $('#WarrantSearch').find('.mainContent').find('.scope').height() - 300
                    H = 300
                } else {
                    scopeH = $('#WarrantSearch').find('.mainContent').find('.scope').height() + 100
                    H = 600
                }
                scopeW = $('#WarrantSearch').find('.mainContent').find('.scope').width()
                if ($('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.col').eq(0).length) {
                    table1 = $('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.col').eq(0).width()
                }
                if ($('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.col').eq(1).length) {
                    table2 = $('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.col').eq(1).width()
                }
                if ($('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.col').eq(2).length) {
                    table3 = $('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.col').eq(2).width()
                }
                if (windowT > H) {
                    if (scopeW > (table1 + table2 + table3)) {
                        $('#WarrantSearch').find('.mainContent').find('.shift').hide()
                    } else {
                        $('#WarrantSearch').find('.mainContent').find('.shift').show()
                    }
                }
            })
            $(window).scroll(function () {
                windowT = $(this).scrollTop()
                if (windowT > H) {
                    if (scopeW > (table1 + table2 + table3)) {
                        $('#WarrantSearch').find('.mainContent').find('.shift').hide()
                    } else {
                        $('#WarrantSearch').find('.mainContent').find('.shift').show()
                    }
                }
                if (windowT <= H || windowT > scopeH) {
                    $('#WarrantSearch').find('.mainContent').find('.shift').hide()
                }
            })
        },
        // 点击移动列表
        clickShiftRight: function () {
            let scrollRight = $('.tableBox').scrollLeft()
            $('.tableBox').scrollLeft(scrollRight + 10)
        },
        clickShiftLeft: function () {
            let scrollRight = $('.tableBox').scrollLeft()
            $('.tableBox').scrollLeft(scrollRight - 10)
        },
        pageSkipWarrantComparison: function () {
            if (this.WarrantComparisonRICArr.length >= 2) {
                this.$router.push({
                    name: "Warrant Comparison",
                    params: { ricArr: this.WarrantComparisonRICArr.join(",") }
                });
            }
        },
        // 出现combobox
        ariseCombobox: function (index, name) {
            let _this = this
            this.$$.jqcombobox('#comboboxSelect' + index)
            $('#comboboxSelect' + index).combobox({
                select: function (event, ui) {
                    switch (index) {
                        case 1:
                            _this.typeD = this.value
                            break;
                        case 3:
                            _this.sensitivityD = this.value
                            break;
                        case 4:
                            _this.maturityD = this.value
                            break;
                        case 5:
                            _this.timedecaryD = this.value
                            break;
                        case 6:
                            _this.effectiveGearingD = this.value
                            break;
                        case 7:
                            _this.moneynessD = this.value
                            break;
                    }
                    $('#comboboxSelect' + index).parent().find('input').val(ui.item.innerText)
                    if (index !== 5 || index !== 0) {
                        _this.$$.inputEmpty('.WarrantSearchHunt' + index)
                    }
                }
            });

            if (index !== 5 || index !== 0) {
                this.$$.inputEmpty('.WarrantSearchHunt' + index)
            }
        },
    }
};